<script setup>
import { onMounted, ref, watch, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { returnLanguage } from '../../assets/js/language'
import AboutBanner from './components/about-banner'
import NewList from './components/newlist'
import { getList } from '@/api/new'

const store = useStore()
// 拿到vuex中的分类列表
const langtype = store.state.home.type
// eslint-disable-next-line no-undef
const langvalue = ref(returnLanguage(langtype))
// console.log(langvalue.value.top.one, '测试')

const checkedtype = ref(langvalue.value.news.one)
const type = ref(langtype)
const page = ref(1)
const limit = ref(100)
const productList = ref([])

const getNewList = () => {
  getList(page.value, limit.value, 6).then(res => {
    let {data: {count, list }} = res
    productList.value = list
    console.log(list, '测试');
  })
}

onMounted(() => {
  getNewList()
})
watch(
  () => store.state.home.type,
  newVal => {
    langvalue.value = returnLanguage(newVal)
    checkedtype.value = returnLanguage(newVal).news.one
    type.value = newVal
    getNewList()
  },
  { immediate: true }
)
</script>
<template>
  <div class="connew">
    <AboutBanner :checkedtype="checkedtype" title="COMPANY NEWS" />
    <div class="new_main">
      <div class="new_list">
        <NewList :productList="productList" :cateId="6" />
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.connew {
  background: #f8f8f8;
  .new_main {
    width: 1340px;
    padding: 96px 0 200px ;
    margin: 0 auto;
  }
}
</style>
